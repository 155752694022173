/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Layout
# Helper classes
# Navigation
# Elements

--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
*, :after, :before {
  box-sizing: border-box; }

* {
  font-family: 'Inter var', system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  /*font-weight: normal;*/ }

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d3436; }

body {
  direction: ltr;
  text-rendering: optimizeLegibility; }

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter var', system-ui, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  font-style: normal; }

h1.about-author {
  margin-top: 0; }

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin: 2.5rem 0 0; }

.entry-content a {
  color: #246FC8;
  text-decoration: none;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  box-shadow: inset 0 -1.5px 0 #8CAFD3; }

.entry-content a:hover {
  color: #8D95E0;
  box-shadow: none; }

.entry-content .yarpp-related {
  width: 100%;
  clear: both; }

.entry-content .yarpp-related a {
  box-shadow: none; }

.home h1,
.home h2,
.home h3,
.home h4 {
  margin: 0; }

h1 {
  font-size: 2rem; }
  @media screen and (min-width: 960px) {
    h1 {
      font-size: 2.25rem; } }

h1.entry-title {
  max-width: 800px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0; }

h2 {
  font-size: 1.75rem; }
  @media screen and (min-width: 960px) {
    h2 {
      font-size: 1.875rem; } }

h3 {
  font-size: 1.5rem; }
  .CardItem h3 {
    font-size: 1.25rem; }

h4, h5, h6 {
  font-size: 1.25rem; }

p {
  color: #2d3436; }
  p .entry-content {
    margin-bottom: 2rem; }

.width100 {
  width: 100%; }

::selection {
  background: rgba(246, 192, 30, 0.25);
  color: inherit !important; }

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

a {
  color: #1A202C;
  background-color: transparent;
  text-decoration: none; }

a:hover {
  color: #21219c; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

button,
input {
  /* 1 */
  overflow: visible; }

button,
select {
  /* 1 */
  text-transform: none; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

ul li {
  padding-bottom: 0.25rem; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

figure {
  margin: 0; }

/*--------------------------------------------------------------
# Helper Classes
--------------------------------------------------------------*/
.color-white {
  color: #ffffff; }

.bg-beige {
  background-color: #F0EDE9; }

.bg-gray {
  background: #f2f2f2; }

.bg-dark {
  background-color: #31303a; }

.shadow {
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1); }

.artShadow {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08); }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
.front-header-img {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block; }

.front-header-img figure {
  padding-bottom: 56.25%; }

.front-header-img img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; }

.header {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23F2F2F2' d='M0 0h10v10H0z'/%3E%3Cpath fill='%23E6E5E2' d='M0 0h2v2H0z'/%3E%3C/g%3E%3C/svg%3E"); }

.scrollbox-new {
  width: 100%;
  overflow: hidden; }

.scrollbox-new ul {
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: row;
  list-style-type: none;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  white-space: nowrap;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: none 0s ease 0s;
  transform: none;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.scrollbox ul {
  list-style: none;
  flex-direction: row;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  display: -ms-flexbox !important;
  display: flex !important;
  max-width: 100vw; }

.menu-footer-info-links-container ul {
  list-style-type: none;
  padding-left: 0.25rem; }

.menu-footer-info-links-container li {
  padding: 0.25rem; }

.pills li {
  position: relative;
  padding: 0.5em; }

.main-navigation a {
  display: block;
  text-decoration: none;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  /*text-transform: uppercase;*/ }

.main-navigation a:hover {
  color: #246FC8;
  text-decoration: none;
  box-shadow: inset 0 -1.5px 0 #8CAFD3; }

.menu-toggle {
  background: #ffffff;
  border: none; }

@media screen and (min-width: 960px) {
  .main-navigation ul {
    display: -ms-flexbox !important;
    display: flex !important;
    flex-direction: row; } }

.nav-cta {
  display: none; }

@media screen and (min-width: 960px) {
  .nav-cta {
    display: -ms-flexbox !important;
    display: flex !important; } }

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */ }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 1em;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .call-header {
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
    background: #e41f33;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(-135deg, #642950 0, #e41f33 100%);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(-135deg, #642950 0, #e41f33 100%);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .call-header a {
    color: #ffffff;
    font-weight: bold; } }

@media all and (min-width: 768px) {
  .call-header {
    display: none; } }

section {
  position: relative; }

.link-container {
  text-decoration: none;
  display: block;
  padding-bottom: 1rem; }

ul.card-post {
  list-style-type: none;
  padding: 0; }

.card-post a {
  position: relative;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: .75rem;
  margin-bottom: 30px; }
  .card-post a:first-child {
    margin-bottom: 1rem; }

.card-post h3 {
  padding: 0.5rem 0.5rem 0; }

.card-post li {
  padding-bottom: 1rem; }
  .card-post li a {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem); }
    @media screen and (min-width: 960px) {
      .card-post li a {
        margin: 0;
        width: 100%; } }

.image-container {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px; }

.image-container figure {
  padding-bottom: 56.25%; }

.image-container img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; }

.card {
  display: -ms-flexbox !important;
  display: flex !important;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%; }

.card-content {
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 100%;
  border-radius: 3px;
  z-index: 1;
  background: #ffffff; }

.card-content p {
  flex: 1 0 auto;
  margin: 0; }

.hover-translate-y-n10:hover, .translate-y-n10 {
  transform: translateY(-10px) !important; }

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important; }

[class*="shadow"] {
  transition: all .2s ease; }

.post-thumbnail {
  max-width: 800px;
  width: 100%;
  margin: auto; }

.post-thumbnail figure {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 56.25% 0 0 0; }

.post-thumbnail img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.entry-header {
  overflow: hidden; }

.pro-header-img {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  width: 1000px; }
  @media all and (min-width: 768px) {
    .pro-header-img {
      border-radius: 8px; } }
  .pro-header-img figure {
    padding-bottom: 56.25%; }
  .pro-header-img img {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; }

.pro-header-txt {
  /*padding: 101px 0 125px 96px;*/
  max-width: 800px;
  width: 100%;
  margin: 3rem auto; }

.no-gutters {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.pills ul {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d2d7dc; }

.pills a {
  color: #E71E27;
  background-color: #FFEBEE;
  padding: 0.25rem 1rem;
  display: block;
  border-radius: 24px;
  font-size: 12px; }

.users-says p {
  font-size: 1.75rem;
  padding: 0 1rem;
  margin: 0; }

/*
#Swiper slider
*/
.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #ffffff;
  opacity: 0.2;
  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.4; }

.swiper-slide-active {
  opacity: 1; }

.review {
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: column;
  padding: 3rem 1rem;
  border-radius: 3px; }

.review:before {
  display: block;
  content: '';
  width: 43px;
  height: 32px;
  background-image: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-before.svg");
  background-repeat: no-repeat;
  left: 16px;
  top: 16px;
  position: absolute; }

.review:after {
  display: block;
  content: '';
  width: 43px;
  height: 32px;
  background-image: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-after.svg");
  background-repeat: no-repeat;
  right: 16px;
  bottom: 16px;
  position: absolute; }

.review h4 {
  margin: 0.5rem 0 0; }

.review p.small {
  margin: 0 0 0.5rem; }

/*--------------------------------------------------------------
# Quote
--------------------------------------------------------------*/
.first-step {
  background: #ffffff;
  border-radius: 3px;
  padding: 1rem 0.5rem;
  width: 100%; }

.quote-title {
  display: block;
  padding-bottom: 0rem;
  margin: 0; }

.quote-title,
.green-text {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem; }

.green-text {
  color: #48BB78; }

.btn,
.next-page a,
.prev-page a {
  display: inline-block;
  border-radius: 3px;
  padding: 12px 20px;
  vertical-align: middle;
  color: #fff;
  border: none;
  outline: none;
  text-decoration: none;
  background: #272e3a;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform, box-shadow;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -100px 0 rgba(0, 0, 0, 0) inset; }

.nav-cta-btn {
  padding: 11px 18px 10px;
  font-size: 13px;
  background: #f4511c;
  box-shadow: none;
  margin-left: 1rem;
  line-height: 1; }

.btn:hover,
.btn:focus {
  color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -100px 0 rgba(0, 0, 0, 0.15) inset; }

.btn-active {
  background: #3260B1;
  color: #ffffff; }

.btn-call-cta {
  background: #e8e8e8;
  color: rgba(13, 17, 43, 0.8);
  box-shadow: none;
  display: none; }
  @media screen and (min-width: 1080px) {
    .btn-call-cta {
      display: flex; } }

.btn-cta {
  background: #f4511c;
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  line-height: 1.2; }

.btn-orange-outline {
  width: 100%;
  border: 3px solid #f4511c;
  border-radius: 3px;
  background: #ffffff;
  color: #1A202C;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem; }

input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input,
select {
  width: 100%;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 4px 0 40px;
  font-size: 15px;
  line-height: 1.625;
  background-clip: padding-box;
  outline: none;
  transition: border 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
  label.on input, label.on
  select {
    padding: .4rem 40px 0; }

select {
  cursor: pointer; }

.clearfix::after {
  display: block;
  content: "";
  clear: both; }

.pagination {
  position: relative;
  padding: 10px 0;
  font-size: 14px; }
  .pagination::after {
    display: block;
    content: "";
    clear: both; }

.pagination .next-page {
  float: right; }

.pagination .prev-page {
  float: left; }

.quoteBG {
  background-image: linear-gradient(180deg, rgba(223, 181, 52, 0.3) 0%, rgba(223, 181, 52, 0) 100%); }

.separator {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 1px;
  background-color: #e7edf1;
  margin: 32px auto 40px; }

article.postWrapper {
  background: linear-gradient(180deg, #ebf4fd 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 80%, #ebf4fd 100%); }

.author-img img {
  border-radius: 50%; }

.author-img {
  padding-right: 0.75rem; }

.author-bio {
  margin: auto 0; }

:target:before {
  content: "";
  display: block;
  height: 80px;
  margin: -80px 0 0; }

footer .footer-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 10px; }

footer .row ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 16px;
  line-height: 1.4; }

footer .row ul li {
  padding-bottom: .4em; }

#myIframe {
  width: 100%;
  margin-bottom: 1rem; }
  @media all and (min-width: 768px) {
    #myIframe {
      max-width: 360px;
      margin-bottom: 0; }
      .estimator-page #myIframe {
        max-width: 100%; } }

.quote-articke-bg {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  border-left: none;
  border-right: none;
  background: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-article-background.svg") no-repeat bottom, center; }
  @media all and (min-width: 768px) {
    .quote-articke-bg {
      border-radius: 8px;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      border-right: 1px solid rgba(0, 0, 0, 0.15); } }
  .quote-articke-bg #myIframe {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 768px) {
      .quote-articke-bg #myIframe {
        width: 100%;
        max-width: 360px; } }

p#breadcrumbs {
  margin: 32px auto 0px; }

/***** POST NAV *****/
.single .navigation.post-navigation {
  width: 100%;
  padding-top: 0; }

.single .nav-previous {
  background-image: linear-gradient(135deg, #E2E5ED 10%, #F1F4F4 100%);
  height: 200px;
  display: table;
  border-radius: 7px 0 0 0; }

.single .nav-next {
  background-image: linear-gradient(135deg, #FEB692 10%, #EA5455 100%);
  height: 200px;
  display: table;
  border-radius: 0 7px 0 0; }

.single .nav-previous a, .single .nav-next a {
  border: none;
  margin: auto;
  padding: 0 20px;
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  display: table-cell;
  vertical-align: middle; }

.single .nav-links div:only-child {
  width: 100%;
  border-radius: 7px 7px 0 0; }

.single .nav-next a {
  color: #ffffff; }

.single .nav-previous a:hover, .single .nav-next a:hover {
  background: none;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out; }

.single .nav-previous a::before {
  content: "PREVIOUS POST";
  display: block;
  font-size: 13px; }

.single .nav-next a::before {
  content: "NEXT POST";
  display: block;
  font-size: 13px; }

.single .nav-next a::after {
  content: none; }

.post-navigation .nav-previous,
.post-navigation .nav-next {
  float: left;
  width: 50%; }

@media (max-width: 575.98px) {
  .post-navigation .nav-previous, .post-navigation .nav-next, .single .nav-links div:only-child {
    width: 100%;
    border-radius: 0; } }

dl {
  margin-top: 0;
  margin-bottom: 1rem; }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

.logo-list {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .logo-list img {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }

.copyright {
  color: #777;
  font-size: 14px; }

.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container {
  max-width: 1140px;
  margin: auto; }

.PageContainer {
  max-width: 1440px;
  margin: 0 auto; }

.GridItem,
.CardItem {
  width: 100%;
  margin: 0.5rem;
  display: flex; }
  @media all and (min-width: 768px) {
    .GridItem,
    .CardItem {
      padding: 0.5em; } }

@media all and (min-width: 768px) {
  .mx-1-sum {
    margin: 0 0.5em; } }

footer.site-footer .GridItem {
  width: calc(50% - 1rem); }

@media (min-width: 576px) {
  .GridItem,
  .CardItem {
    width: calc(50% - 1rem); } }

@media all and (min-width: 48rem) {
  .GridItem,
  footer.site-footer .GridItem {
    width: calc(33.33% - 1rem); }
  .CardItem {
    width: calc(25% - 1rem); } }

.BlogPostExc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none; }

.BlogPostExc .post-title {
  color: #31303a; }

.BlogPostExc .post-title h3 {
  margin: 0.5rem 0; }

.entry-content {
  position: relative;
  max-width: 820px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  background: #ffffff; }
  @media all and (min-width: 768px) {
    .entry-content {
      width: 96%; } }
  @media (min-width: 960px) {
    .entry-content {
      padding: 48px 56px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

[class*="col-"],
[class*="int-"] {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; } }

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.entry-content .alignwide {
  margin-left: -80px;
  margin-right: -80px; }

.entry-content .alignfull {
  margin-left: calc( -100vw / 2 + 100% / 2);
  margin-right: calc( -100vw / 2 + 100% / 2);
  max-width: 100vw; }

.alignfull img {
  width: 100vw; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.text-center {
  text-align: center; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

.pt-1,
.py-1 {
  padding-top: 1em; }

.pt-2,
.py-2 {
  padding-top: 2em; }

.pt-3,
.py-3 {
  padding-top: 3em; }

.pt-4,
.py-4 {
  padding-top: 4em; }

.pt-5,
.py-5 {
  padding-top: 5em; }

.pb-1,
.py-1 {
  padding-bottom: 1em; }

.pb-2,
.py-2 {
  padding-bottom: 2em; }

.pb-3,
.py-3 {
  padding-bottom: 3em; }

.pb-4,
.py-4 {
  padding-bottom: 4em; }

.pb-5,
.py-5 {
  padding-bottom: 5em; }

.mt-1,
.my-1 {
  margin-top: 1rem; }

.mt-2,
.my-2 {
  margin-top: 2rem; }

.mt-3,
.my-3 {
  margin-top: 3rem; }

.mt-4,
.my-4 {
  margin-top: 4rem; }

.mt-5,
.my-5 {
  margin-top: 5rem; }

.mb-1,
.my-1 {
  margin-bottom: 1rem; }

.mb-2,
.my-2 {
  margin-bottom: 2rem; }

.mb-3,
.my-3 {
  margin-bottom: 3rem; }

.mb-4,
.my-4 {
  margin-bottom: 4rem; }

.mb-5,
.my-5 {
  margin-bottom: 5rem; }

#masthead {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #ffffff; }
  body.admin-bar #masthead {
    top: 32px; }

.site-header {
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media all and (min-width: 48rem) {
    .site-header {
      padding: 1rem 0.5rem; } }

.main-navigation {
  display: none; }
  .main-navigation ul {
    list-style: none;
    margin: 0;
    padding-left: 0; }
  .main-navigation li {
    float: left;
    position: relative;
    width: 100%; }
  .main-navigation li:first-child {
    margin-top: 1rem; }
  .main-navigation a {
    display: block;
    margin-bottom: 8px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    padding: 16px 14px 14px;
    font-size: 12px;
    color: #0d112b;
    background: #f9f9f9; }
  @media screen and (min-width: 60rem) {
    .main-navigation {
      display: flex; }
    .main-navigation li {
      padding: 0.5rem;
      width: auto; }
    .main-navigation a {
      text-transform: none;
      font-size: 15px;
      padding: 0.25rem 0.1rem;
      background: none;
      margin-bottom: 0;
      letter-spacing: 0.2px;
      border-radius: 0; }
    .main-navigation li:first-child {
      margin-top: 0; } }

/* Small menu. */
.menu-toggle,
.main-navigation.toggled {
  display: flex; }

@media screen and (min-width: 60rem) {
  .menu-toggle {
    display: none; }
  .main-navigation ul {
    display: block; } }

#quoteFrame {
  padding: 0;
  max-width: 100%; }
  @media screen and (min-width: 960px) {
    #quoteFrame {
      max-width: 350px; } }

input#from_zip {
  border-radius: 3px;
  width: 100%;
  padding-left: 2.5rem; }

select#to_state,
select#from_country {
  padding-left: 2.5rem; }

.us input#to_city {
  padding-left: 0.5rem;
  border-radius: 3px; }

#from_zip,
#to_state {
  padding-left: 3rem; }

.one-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

#to-state,
#to-city {
  width: 100%; }

.field-wrapper.point-a::before, .field-wrapper.point-b::before {
  display: block;
  position: absolute;
  background: #718096;
  color: #EDF2F7;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 16px;
  line-height: 1.5;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  pointer-events: none;
  text-align: center; }

.field-wrapper.point-a::before {
  content: "A"; }

.field-wrapper.point-b::before {
  content: "B"; }

.field-wrapper.line-c:before {
  content: '';
  height: 20px;
  width: 1px;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: #718096; }

.price-finder-slogan {
  font-size: 17px;
  color: #555;
  margin: 5px auto; }

.price-finder-city-to {
  margin: 0 0 8px;
  float: left;
  width: 118px;
  border: 1px solid #999;
  border-radius: 3px;
  font-size: 16px;
  padding: 6px 3px 8px 30px;
  box-sizing: border-box; }

.from_city1_container.int-small, .price-finder-city-to.int-small {
  /*width: 100%;*/
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 50%; }

.from_city1_container.int-big {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%; }

#from_admin1_container-pf, #to_admin1_container-pf {
  display: none;
  /*width: 50%;*/
  width: 50%;
  position: relative; }

.price-finder-zip:focus, .price-finder-zip:active, .price-finder-zip.focused, .price-finder-to-city:focus, .price-finder-to-city:active, .price-finder-to-city.focused, .price-finder-to-state:focus, .price-finder-to-state:active, .price-finder-to-state.focused, .price-finder-city:focus, .price-finder-city:active, .price-finder-city.focused, .price-finder-city-to:focus, .price-finder-city-to:active, .price-finder-city-to.focused {
  border: 1px solid #0366d6;
  outline: none; }

.price-finder-to-city {
  margin: 0 0 0 5px;
  width: 123px; }

.price-finder-to, .from-zip {
  position: relative;
  z-index: 0; }

.float-label.international #from_admin1_container-pf .order_by-arrow-down, .float-label.international #to_admin1_container-pf .order_by-arrow-down {
  top: 40px; }

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.typeahead .active a {
  background: #049147 !important; }

.price-finder-to ul.typeahead.dropdown-menu {
  right: -3px !important;
  text-align: left; }

.navbar .nav > li > .dropdown-menu:after {
  border: none !important; }

.typeahead .active a {
  background: #049147 !important; }

.navbar .nav > li > .dropdown-menu:after {
  border: none !important; }

.navbar .nav > li > .dropdown-menu:before {
  border: none !important; }

.dropdown-submenu > .dropdown-menu {
  left: 0 !important;
  margin-top: 30px !important; }

.divider-vertical {
  margin: 0 !important;
  height: 30px !important; }

.dropdown-menu .divider {
  margin: 0 !important; }

.dropdown-menu {
  font-size: 14px;
  text-align: left;
  list-style: none; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.navbar .nav > li > .dropdown-menu:before {
  border: none !important; }

.dropdown-submenu > .dropdown-menu {
  left: 0 !important;
  margin-top: 30px !important; }

.dropdown-menu {
  background-color: #EFEFEF !important; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent; }

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.web_arrow {
  width: 0;
  height: 0;
  z-index: 9;
  pointer-events: none;
  position: absolute; }

.web_arrow.arrow_down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #718096;
  top: 18px;
  right: 16px; }

.field-wrapper {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 44px; }

label {
  position: absolute;
  top: 4px;
  left: 48px;
  font-size: 11px;
  color: #aaa;
  transition: all 0.1s linear;
  opacity: 0;
  font-weight: bold;
  pointer-events: none; }
  label[for="to_city"] {
    left: 18px; }

label.on {
  color: #0366d6; }
  label.on ~ input,
  label.on ~ select {
    border-color: #0366d6; }
  label.on.show ~ input,
  label.on.show ~ select {
    padding-top: 1rem; }

label.show {
  top: 4px;
  opacity: 1; }
  label.show ~ input,
  label.show ~ select {
    padding-top: 1rem; }

option {
  color: black; }

.watermark {
  color: #aaa; }

.alsoRead {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 15px; }
  .alsoRead h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 0;
    margin: 0; }
  .alsoRead ul {
    list-style-type: none;
    padding: 0; }
    .alsoRead ul li {
      padding: 0 0 0.6em; }
      .alsoRead ul li a {
        font-size: 18px; }

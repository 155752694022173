#quoteFrame {
  padding: 0;
  max-width: 100%;

  @media screen and (min-width: 960px) {
    max-width: 350px;
  }
}

input#from_zip {
  border-radius: 3px;
  width: 100%;
  padding-left: 2.5rem;
}

select#to_state,
select#from_country{
  padding-left: 2.5rem;
}

.us input#to_city {
  padding-left: 0.5rem;
  border-radius: 3px;
}

#from_zip,
#to_state {
  padding-left: 3rem;
}

.one-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#to-state,
#to-city {
  width: 100%;
}

.field-wrapper.point-a::before, .field-wrapper.point-b::before {
  display: block;
  position: absolute;
  background: #718096;
  color: #EDF2F7;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 16px;
  line-height: 1.5;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  pointer-events: none;
  text-align: center;
}

.field-wrapper.point-a::before {
  content: "A";
}

.field-wrapper.point-b::before {
  content: "B";
}

.field-wrapper.line-c:before {
  content: '';
  height: 20px;
  width: 1px;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: #718096;
}

.price-finder-slogan {
  font-size: 17px;
  color: #555;
  margin: 5px auto;
}

.price-finder-city-to {
  //background: url(https://www.mymovingreviews.com/quote/img/steps-us3/step-quote-sprite.png) no-repeat scroll 0px -27px #fff;
  margin: 0 0 8px;
  float: left;
  width: 118px;
  border: 1px solid #999;
  border-radius: 3px;
  font-size: 16px;
  padding: 6px 3px 8px 30px;
  box-sizing: border-box;
}
.from_city1_container.int-small, .price-finder-city-to.int-small {
  /*width: 100%;*/
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 50%;
}
.from_city1_container.int-big {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

#from_admin1_container-pf, #to_admin1_container-pf {
  display: none;
  /*width: 50%;*/
  width: 50%;
  position: relative;
}

.price-finder-zip:focus, .price-finder-zip:active, .price-finder-zip.focused, .price-finder-to-city:focus, .price-finder-to-city:active, .price-finder-to-city.focused, .price-finder-to-state:focus, .price-finder-to-state:active, .price-finder-to-state.focused, .price-finder-city:focus, .price-finder-city:active, .price-finder-city.focused, .price-finder-city-to:focus, .price-finder-city-to:active, .price-finder-city-to.focused {
  //box-shadow: 0 0 5px 1px #67B233;
  border: 1px solid #0366d6;
  outline: none;
}


.price-finder-to-city {
  margin: 0 0 0 5px;
  width: 123px;
}

.price-finder-to, .from-zip {
  position: relative;
  z-index: 0;
}

.float-label.international #from_admin1_container-pf .order_by-arrow-down, .float-label.international #to_admin1_container-pf .order_by-arrow-down {
  top: 40px;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}


.typeahead .active a {
  background: #049147 !important;
}
.price-finder-to ul.typeahead.dropdown-menu {
  right: -3px !important;
  text-align: left;
}

.navbar .nav > li > .dropdown-menu:after {
  border: none !important;
}

.typeahead .active a {
  background: #049147 !important;
}

.navbar .nav > li > .dropdown-menu:after {
  border:none !important;
}
.navbar .nav > li > .dropdown-menu:before {
  border:none !important;
}
.dropdown-submenu > .dropdown-menu {
  left:0 !important; margin-top: 30px !important;
}
.divider-vertical {
  margin:0 !important; height:30px !important;
}
.dropdown-menu .divider {
  margin:0 !important;
}
.dropdown-menu {
  font-size: 14px;
  text-align: left;
  list-style: none;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.navbar .nav > li > .dropdown-menu:before {
  border:none !important;
}
.dropdown-submenu > .dropdown-menu {
  left:0 !important; margin-top: 30px !important;
}

.dropdown-menu {
  background-color:#EFEFEF !important;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.web_arrow {
  width: 0;
  height: 0;
  z-index: 9;
  pointer-events: none;
  position: absolute;
}

.web_arrow.arrow_down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #718096;
  top: 18px;
  right: 16px;
}
$white: #ffffff;
$orangeCta: #f4511c;

// Extra small screen / phone
$screen-xs:                  480px !default;

// Small screen / tablet
$screen-sm:                  768px !default;

// Medium screen / desktop
$screen-md:                  992px !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Layout
# Helper classes
# Navigation
# Elements

--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/

*, :after, :before {
  box-sizing: border-box;
}

* {
  font-family: 'Inter var', system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  /*font-weight: normal;*/
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d3436;
}

body {
  direction: ltr;
  text-rendering: optimizeLegibility;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter var', system-ui, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  font-style: normal;
}

h1.about-author {
  margin-top: 0;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin: 2.5rem 0 0;
}

.entry-content a {
  color: #246FC8;
  text-decoration: none;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  box-shadow: inset 0 -1.5px 0 #8CAFD3;
}

.entry-content a:hover {
  color: #8D95E0;
  box-shadow: none;
}

.entry-content .yarpp-related {
  width: 100%;
  clear: both;
}

.entry-content .yarpp-related a {
  box-shadow: none;
}

.home h1,
.home h2,
.home h3,
.home h4 {
  margin: 0;
}

h1 {
  font-size: 2rem;

  @media screen and (min-width: 960px) {
    font-size: 2.25rem;
  }
}

h1.entry-title {
  max-width: 800px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0;
}

h2 {
  font-size: 1.75rem;

  @media screen and (min-width: 960px) {
    font-size: 1.875rem;
  }
}

h3 {
  font-size: 1.5rem;

  .CardItem & {
    font-size: 1.25rem;
  }
}

h4, h5, h6 {
  font-size: 1.25rem;
}

p {
  color: #2d3436;

  & .entry-content {
    margin-bottom: 2rem;
  }
}

.width100 {
  width: 100%;
}

::selection {
  background: rgba(246, 192, 30, 0.25);
  color: inherit !important;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  color: #1A202C;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #21219c;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

button,
input { /* 1 */
  overflow: visible;
}

button,
select { /* 1 */
  text-transform: none;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

ul li {
  padding-bottom: 0.25rem;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

figure {
  margin: 0;
}

/*--------------------------------------------------------------
# Helper Classes
--------------------------------------------------------------*/

.color-white {
  color: $white;
}

.bg-beige {
  background-color: #F0EDE9;
}

.bg-gray {
  background: #f2f2f2;
}

.bg-dark {
  background-color: #31303a;
}

.shadow {
  box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.1);
}

.artShadow {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
}

.d-flex {
  @include d-flex;
}

.flex-row {
  -ms-flex-direction: row!important;
  flex-direction: row!important;
}

.justify-content-start {
  -ms-flex-pack: start!important;
  justify-content: flex-start!important;
}

.justify-content-end {
  -ms-flex-pack: end!important;
  justify-content: flex-end!important;
}

.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.justify-content-between {
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}

.justify-content-around {
  -ms-flex-pack: distribute!important;
  justify-content: space-around!important;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

.front-header-img {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
}

.front-header-img figure {
  padding-bottom: 56.25%;
}

.front-header-img img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.header {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23F2F2F2' d='M0 0h10v10H0z'/%3E%3Cpath fill='%23E6E5E2' d='M0 0h2v2H0z'/%3E%3C/g%3E%3C/svg%3E");
}

.scrollbox-new {
  width: 100%;
  overflow: hidden;
}

.scrollbox-new ul {
  @include d-flex;
  flex-direction: row;
  list-style-type: none;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  white-space: nowrap;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: none 0s ease 0s;
  transform: none;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.scrollbox ul {
  list-style: none;
  flex-direction: row;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include d-flex;
  max-width: 100vw;
}

.menu-footer-info-links-container ul{
  list-style-type: none;
  padding-left: 0.25rem;
}

.menu-footer-info-links-container li {
  padding: 0.25rem;
}

.pills li {
  position: relative;
  padding: 0.5em;
}

.main-navigation a {
  display: block;
  text-decoration: none;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  /*text-transform: uppercase;*/
}

.main-navigation a:hover {
  color: #246FC8;
  text-decoration: none;
  box-shadow: inset 0 -1.5px 0 #8CAFD3;
}

.menu-toggle {
  background: $white;
  border: none;
}

@media screen and (min-width: 960px) {
  .main-navigation ul {
    @include d-flex;
    flex-direction: row;
  }
}

.nav-cta {
  display: none;
}

@media screen and (min-width: 960px) {
  .nav-cta {
    @include d-flex;
  }
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 1em;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .call-header {
    @include d-flex;
    width: 100%;
    background: #e41f33;  /* fallback for old browsers */
    background: -webkit-linear-gradient(-135deg,#642950 0,#e41f33 100%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(-135deg,#642950 0,#e41f33 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .call-header a {
    color: $white;
    font-weight: bold;
  }
}

@media all and (min-width: 768px) {
  .call-header {
    display: none;
  }
}

section {
  position: relative;
}

.link-container {
  text-decoration: none;
  display: block;
  padding-bottom: 1rem;
}

ul.card-post {
  list-style-type: none;
  padding: 0;
}

.card-post a {
  position: relative;
  @include d-flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: .75rem;
  margin-bottom: 30px;

  &:first-child {
    margin-bottom: 1rem;
  }
}

.card-post h3 {
  padding: 0.5rem 0.5rem 0;
}

.card-post li {
  padding-bottom: 1rem;

  & a {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);

    @media screen and (min-width: 960px) {
      margin: 0;
      width: 100%;
    }
  }
}

.image-container {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 3px;
}

.image-container figure {
  padding-bottom: 56.25%;
}

.image-container img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.card {
  @include d-flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
}
.card-content {
  @include d-flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 100%;
  border-radius: 3px;
  z-index: 1;
  background: $white;
}
.card-content p {
  flex: 1 0 auto;
  margin: 0;
}

.hover-translate-y-n10:hover, .translate-y-n10 {
  transform: translateY(-10px) !important;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(31,45,61,.125) !important;
}

[class*="shadow"] {
  transition: all .2s ease;
}

.post-thumbnail {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.post-thumbnail figure {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 56.25% 0 0 0;
}

.post-thumbnail img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.entry-header {
  overflow: hidden;
}

.pro-header-img {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  width: 1000px;

  @media all and (min-width: 768px) {
    border-radius: 8px;
  }

  & figure {
    padding-bottom: 56.25%;
  }

  & img {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.pro-header-txt {
  /*padding: 101px 0 125px 96px;*/
  max-width: 800px;
  width: 100%;
  margin: 3rem auto;
}

.no-gutters {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.pills ul {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #d2d7dc;
}

.pills a {
  color: #E71E27;
  background-color: #FFEBEE;
  padding: 0.25rem 1rem;
  display: block;
  border-radius: 24px;
  font-size: 12px;
}

.users-says p {
  font-size: 1.75rem;
  padding: 0 1rem;
  margin: 0;
}

/*
#Swiper slider
*/

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: $white;
  opacity: 0.2;
  height: auto;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.4;
}

.swiper-slide-active {
  opacity: 1;
}

.review {
  @include d-flex;
  flex-direction: column;
  padding: 3rem 1rem;
  border-radius: 3px;
}

.review:before {
  display: block;
  content: '';
  width: 43px;
  height: 32px;
  background-image: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-before.svg");
  background-repeat: no-repeat;
  left: 16px;
  top: 16px;
  position: absolute;
}

.review:after {
  display: block;
  content: '';
  width: 43px;
  height: 32px;
  background-image: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-after.svg");
  background-repeat: no-repeat;
  right: 16px;
  bottom: 16px;
  position: absolute;
}

.review h4 {
  margin: 0.5rem 0 0;
}

.review p.small {
  margin: 0 0 0.5rem;
}

/*--------------------------------------------------------------
# Quote
--------------------------------------------------------------*/

.first-step {
  background: $white;
  border-radius: 3px;
  padding: 1rem 0.5rem;
  width: 100%;
}

//@media screen and (min-width: 46rem) {
//  .first-step {
//    max-width: 360px;
//  }
//}

.quote-title {
  display: block;
  padding-bottom: 0rem;
  margin: 0;
}

.quote-title,
.green-text {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.green-text {
  color: #48BB78;
}

.btn,
.next-page a,
.prev-page a{
  display: inline-block;
  border-radius: 3px;
  padding: 12px 20px;
  vertical-align: middle;
  color: #fff;
  border: none;
  outline: none;
  text-decoration: none;
  background: #272e3a;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  will-change: transform, box-shadow;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1),0 -100px 0 rgba(0,0,0,0) inset;
}

.nav-cta-btn{
  padding: 11px 18px 10px;
  font-size: 13px;
  background: $orangeCta;
  box-shadow: none;
  margin-left: 1rem;
  line-height: 1;
}

.btn:hover,
.btn:focus {
  color: $white;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1),0 -100px 0 rgba(0,0,0,0.15) inset;
}

.btn-active {
  background: #3260B1;
  color: $white;
}

.btn-call-cta {
  background: #e8e8e8;
  color: rgba(13,17,43,.8);
  box-shadow: none;
  display: none;

  @media screen and (min-width: 1080px) {
    display: flex;
  }
}

.btn-cta {
  background: $orangeCta;
  width: 100%;
  height: 44px;
  border: none;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  line-height: 1.2;
}

.btn-orange-outline {
  width: 100%;
  border: 3px solid #f4511c;
  border-radius: 3px;
  background: $white;
  color: #1A202C;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

input,
select,
button {
  -webkit-appearance:     none;
  -moz-appearance:        none;
  -ms-appearance:         none;
  -o-appearance:          none;
  appearance:             none;
}

input,
select {
  //background-color: #EDF2F7;
  width: 100%;
  height: 44px;
  background-color: hsla(0,0%,100%,.9);
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 4px;
  padding: 0 4px 0 40px;
  font-size: 15px;
  line-height: 1.625;
  background-clip: padding-box;
  outline: none;
  transition: border .15s cubic-bezier(.4,0,.2,1);

  label.on & {
    padding: .4rem 40px 0;
  }
}

select {
  cursor: pointer;
}

.clearfix {
  @include clearfix;
}

.pagination {
  @include clearfix;
  position: relative;
  padding: 10px 0;
  font-size: 14px;
}

.pagination .next-page {
  float: right;
}

.pagination .prev-page {
  float: left;
}

.quoteBG {
  background-image: linear-gradient(180deg, rgba(223,181,52,0.3) 0%, rgba(223,181,52,0) 100%);
}

.separator {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 1px;
  background-color: #e7edf1;
  margin: 32px auto 40px;
}

article.postWrapper {
  background: linear-gradient(180deg, #ebf4fd 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 80%, #ebf4fd 100%);
}

.author-img img {
  border-radius: 50%;
}
.author-img {
  padding-right: 0.75rem;
}

.author-bio {
  margin: auto 0;
}

:target:before {
  content: "";
  display: block;
  height: 80px;
  margin: -80px 0 0;
}

footer .footer-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 10px;
}

footer .row ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 16px;
  line-height: 1.4;
}

footer .row ul li {
  padding-bottom: .4em;
}

#myIframe {
  width: 100%;
  margin-bottom: 1rem;

  @media all and (min-width: 768px) {
    max-width: 360px;
    margin-bottom: 0;

    .estimator-page & {
      max-width: 100%;
    }
  }
}

.quote-articke-bg {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  border-left: none;
  border-right: none;
  background: url("https://www.xpressmovers.friew.com/wp-content/themes/Xpress/img/quote-article-background.svg") no-repeat bottom, center;

  @media all and (min-width: 768px) {
    border-radius: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }

  & #myIframe {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media all and (min-width: 768px) {
      width: 100%;
      max-width: 360px;
    }
  }
}

p#breadcrumbs {
  margin: 32px auto 0px;
}

/***** POST NAV *****/
.single .navigation.post-navigation{
  width: 100%;
  //margin-bottom: 50px;
  padding-top: 0;
}

.single .nav-previous{
  background-image: linear-gradient( 135deg, #E2E5ED 10%, #F1F4F4 100%);
  height: 200px;
  display: table;
  border-radius: 7px 0 0 0;
}

.single .nav-next{
  background-image: linear-gradient( 135deg, #FEB692 10%, #EA5455 100%);
  height: 200px;
  display: table;
  border-radius: 0 7px 0 0;
}

.single .nav-previous a, .single .nav-next a{
  border: none;
  margin: auto;
  padding: 0 20px;
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  display: table-cell;
  vertical-align: middle;
}

.single .nav-links div:only-child{
  width: 100%;
  border-radius: 7px 7px 0 0;
}

.single .nav-next a{
  color: #ffffff;
}

.single .nav-previous a:hover, .single .nav-next a:hover{
  background: none;
  text-shadow: 1px 1px 6px rgba(0,0,0,0.3);
  transition: all 0.4s ease-in-out;
}

.single .nav-previous a::before{
  content: "PREVIOUS POST";
  display: block;
  font-size: 13px;
}

.single .nav-next a::before{
  content: "NEXT POST";
  display: block;
  font-size: 13px;
}

.single .nav-next a::after {
  content: none;
}

.post-navigation .nav-previous,
.post-navigation .nav-next {
  float: left;
  width: 50%;
}

@media (max-width: 575.98px) {
  .post-navigation .nav-previous, .post-navigation .nav-next, .single .nav-links div:only-child{
    width: 100%;
    border-radius: 0;
  }
}

dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

.logo-list {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  & img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

.copyright {
  color: #777;
  font-size: 14px;
}
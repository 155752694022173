#masthead {
  position: sticky;
  top: 0;
  z-index: 3;
  background: $white;

  body.admin-bar & {
    top: 32px;
  }
}

.site-header {
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (min-width: 48rem) {
    & {
      padding: 1rem 0.5rem;
    }
  }
}

.main-navigation {
  display: none;

  & ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  & li {
    float: left;
    position: relative;
    width: 100%;
  }

  & li:first-child {
    margin-top: 1rem;
  }

  & a {
    display: block;
    margin-bottom: 8px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    padding: 16px 14px 14px;
    font-size: 12px;
    color: #0d112b;
    background: #f9f9f9;
  }

  @media screen and (min-width: 60rem) {
    & {
      display: flex;
    }
    & li {
      padding: 0.5rem;
      width: auto;
    }
    & a {
      text-transform: none;
      font-size: 15px;
      padding: 0.25rem 0.1rem;
      background: none;
      margin-bottom: 0;
      letter-spacing: 0.2px;
      border-radius: 0;
    }
    & li:first-child {
      margin-top: 0;
    }
  }
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled {
  display: flex;
}

@media screen and (min-width: 60rem) {
  .menu-toggle {
    display: none;
  }
  .main-navigation ul {
    display: block;
  }
}
.pt-1,
.py-1 {
  padding-top: 1em;
}

.pt-2,
.py-2 {
  padding-top: 2em;
}

.pt-3,
.py-3 {
  padding-top: 3em;
}

.pt-4,
.py-4 {
  padding-top: 4em;
}

.pt-5,
.py-5 {
  padding-top: 5em;
}

.pb-1,
.py-1 {
  padding-bottom: 1em;
}

.pb-2,
.py-2 {
  padding-bottom: 2em;
}

.pb-3,
.py-3 {
  padding-bottom: 3em;
}

.pb-4,
.py-4 {
  padding-bottom: 4em;
}

.pb-5,
.py-5 {
  padding-bottom: 5em;
}

.mt-1,
.my-1 {
  margin-top: 1rem;
}

.mt-2,
.my-2 {
  margin-top: 2rem;
}

.mt-3,
.my-3 {
  margin-top: 3rem;
}

.mt-4,
.my-4 {
  margin-top: 4rem;
}

.mt-5,
.my-5 {
  margin-top: 5rem;
}

.mb-1,
.my-1 {
  margin-bottom: 1rem;
}

.mb-2,
.my-2 {
  margin-bottom: 2rem;
}

.mb-3,
.my-3 {
  margin-bottom: 3rem;
}

.mb-4,
.my-4 {
  margin-bottom: 4rem;
}

.mb-5,
.my-5 {
  margin-bottom: 5rem;
}

@mixin d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
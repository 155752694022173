.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.entry-content .alignwide {
  margin-left  : -80px;
  margin-right : -80px;
}
.entry-content .alignfull {
  margin-left  : calc( -100vw / 2 + 100% / 2 );
  margin-right : calc( -100vw / 2 + 100% / 2 );
  max-width    : 100vw;
}
.alignfull img {
  width: 100vw;
}

.align-items-start {
  -ms-flex-align: start!important;
  align-items: flex-start!important
}

.align-items-end {
  -ms-flex-align: end!important;
  align-items: flex-end!important
}

.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important
}

.align-items-baseline {
  -ms-flex-align: baseline!important;
  align-items: baseline!important
}

.align-items-stretch {
  -ms-flex-align: stretch!important;
  align-items: stretch!important
}
.text-center {
  text-align: center;
}

.mr-auto,
.mx-auto {
  margin-right: auto!important
}

.ml-auto,
.mx-auto {
  margin-left: auto!important
}
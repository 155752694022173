.alsoRead {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 15px;

  & h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 0;
    margin: 0;
  }

  & ul {
    list-style-type: none;
    padding: 0;

    & li {
      padding: 0 0 0.6em;

      & a {
        font-size: 18px;
      }
    }
  }
}
.field-wrapper {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 44px;
}
label {
  position: absolute;
  top: 4px;
  left: 48px;
  font-size: 11px;
  color: #aaa;
  transition: all 0.1s linear;
  opacity: 0;
  font-weight:bold;
  pointer-events: none;

  &[for="to_city"] {
    left: 18px;
  }
}
label.on {
  color: #0366d6;

  & ~ input,
  & ~ select {
    border-color: #0366d6;
  }

  &.show ~ input,
  &.show ~ select {
    padding-top: 1rem;
  }
}
label.show {
  top: 4px;
  opacity: 1;

  & ~ input,
  & ~ select {
    padding-top: 1rem;
  }
}
option {
  color: black;
}
.watermark {
  color: #aaa;
}